<template>
  <div class="ShopChange">
    <div v-loading="loading"></div>
    <h1 class="ShopChange-h1">商品清单</h1>
    <div v-if="clientWidth > 750">
      <el-table :data="tableData" border :header-row-class-name="tableRowClassName">
        <el-table-column prop="date" label="商品信息">
          <template slot-scope="scope">
            <img :src="scope.row.img" alt="" width="100"/>
            <span class="shop-title">{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="商品数量">
          <template slot-scope="scope">
            <div class="flex0 addsub cur mr" @click="subNum(scope.row.price, shopNum)">-</div>
            <input type="text" class="tc addsubInput" v-model="shopNum" @change="numChange(scope.row.residueNum, scope.row.price, scope.row.onlyBuyOne)" :disabled="scope.row.onlyBuyOne"/>
            <div class="flex0 addsub cur ml" @click="addNum(scope.row.residueNum, scope.row.price, scope.row.onlyBuyOne)">+</div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="商品单价">
          <template slot-scope="scope">
            <div class="flex0 violet" style="font-size: 16px">
              {{ scope.row.price }} 金币
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="商品小计">
          <template slot-scope="scope">
            <div class="flex0" style="font-size: 16px">
              {{ scope.row.price * shopNum }} 金币
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else>
      <div class="mobList">
        <div v-for="item in tableData" :key="item.id" class="mobList-item flex0">
          <div class="mobList-item-img flex">
            <img :src="item.img">
          </div>
          <div class="mobList-item-d">
            <h1>{{ item.title }}</h1>
            <p class="co-999"><span class="violet"> {{ item.price }} </span> 金币</p>
            <div class="flex">
              <div class="flex0 addsub cur mr" @click="subNum(item.price, shopNum)">-</div>
              <input type="text" class="tc addsubInput" v-model.number="shopNum" @change="numChange(item.residueNum, item.price, item.onlyBuyOne)" :disabled="item.onlyBuyOne"/>
              <div class="flex0 addsub cur ml" @click="addNum(item.residueNum, item.price, item.onlyBuyOne)">+</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex5 price-close">
      <p class="available-gold">当前可用金币：{{ Info.money }} 金币</p>
      <p class="actual-payment">实际支付：<b class="violet" style="font-size: 30px">{{ Allprice }}</b> 金币</p>
    </div>
    <div class="price-btn flex5">
      <div class="cancel-detail mr cur flex2">
        <van-icon name="share-o" />
        <router-link :to="'/Home/ShoppingMall/ShopDetail?id=' + $route.query.id">返回商品详情</router-link>
      </div>
      <div @click="submitPrice" class="submit-price co-fff cur">提交订单</div>
    </div>
  </div>
</template>

<script>
import * as Shop from "@/api/Shop.js";

export default {
  name: "ShopChange",
  data() {
    return {
      loading: true,                      // !loading
      Info: this.$store.state.Infos,      // !可用金币数量
      tableData: [],                      // !列表数据
      shopNum: 1,                         // !选择的数量
      Allprice: 0,                        // !实际支付金币
    };
  },
  computed: {
    clientWidth () {
      return this.$store.state.clientWidth;
    },
    Infos() {
      return this.$store.state.Infos;
    },
  },
  mounted() {
    const Id = this.$route.query.id;
    Shop.GetProeuctInfo({ productId: Id }).then((res) => {
      if (res.status == 1) {
        this.tableData.push(res.data);
        if (this.$route.query.num) {
          this.shopNum = this.$route.query.num;
        } else {
          this.shopNum = 1;
        }
        if(res.data.onlyBuyOne) {
          this.shopNum = 1;
        }
        this.Allprice = this.shopNum * res.data.price;
      } else {
        this.$toast(res.message);
        this.$router.push("/Home/ShoppingMall/ShopList");
      }
      this.loading = false;
    });
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 0) {
        return "warning-row";
      }
    },
    // !商品数量加
    addNum(residueNum, price, onlyBuyOne) {
      if(onlyBuyOne) {
        this.shopNum = 1;
        this.$toast('当前商品只允许兑换一次！')
      }else {
        if (this.shopNum < residueNum) {
          this.shopNum++;
        } else {
          this.shopNum = residueNum;
          this.$toast('超出最大可兑换数量，已重置！')
        }
      }
      this.Allprices(price, this.shopNum);
    },
    // !商品数量减
    subNum(price) {
      if (this.shopNum < 2) {
        this.shopNum = 1;
      } else {
        this.shopNum--;
      }
      this.Allprices(price, this.shopNum);
    },
    // !监听输入框内容
    numChange(residueNum, price, onlyBuyOne) {
      if(onlyBuyOne) {
        this.shopNum = 1;
        this.$toast('当前商品只允许兑换一次！')
      }else {
        if (this.shopNum < 2 || !(this.shopNum * 1)) {
          this.shopNum = 1;
        } else if (this.shopNum > residueNum) {
          this.shopNum = residueNum;
          this.$toast('超出最大可兑换数量，已重置！')
        }
      }
      this.Allprices(price, this.shopNum);
    },
    // !商品小计
    Allprices(price, num) {
      this.Allprice = price * num;
    },
    // !提交订单
    submitPrice() {
      if (this.$store.state.token) {
        if (this.Info.money < this.Allprice) {
          this.$toast("您的可用金币不足，无法兑换！");
        } else {
          const Id = { productId: this.$route.query.id };
          this.loading = true;
          Shop.ValidateOrder(Id).then((res) => {
            if (res.status == 1) {
              this.$router.push("/Home/ShopForm?id=" + this.$route.query.id + "&num=" + this.shopNum);
            } else {
              this.loading = false;
              this.$toast(res.message);
              this.$router.push("/Home/ShoppingMall/ShopList");
            }
          });
        }
      } else {
        this.$toast("请先登录后再来兑换吧！");
      }
    },
  },
  watch: {
    Infos(val) {
      this.Info = val;
    },
  },
};
</script>

<style lang="scss" scoped>
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }

  .ShopChange {
    width: 1200px; min-height: 300px; box-shadow: 0 0 10px #ccc; margin: 50px auto; padding: 40px;
    .ShopChange-h1 { font-size: 28px; font-weight: 400; margin-bottom: 25px;}
    .shop-title { font-size: 16px; line-height: 23px; margin-left: 15px;}

    .addsub { width: 25px; height: 30px; border: 1px #ccc solid; background: #eed2fc; line-height: 30px;}
    .addsubInput { width: 100px; height: 26px; border: 1px #ccc solid;}

    /deep/.el-table th > .cell, /deep/.el-table td > .cell { text-align: center;}
    /deep/.el-table tr td:first-of-type .cell { justify-content: flex-start;}

    .price-close { height: 120px; flex-wrap: wrap; padding: 30px 50px;}
    .price-close p { width: 100%; text-align: right;}

    .cancel-detail { padding-right: 40px; display: flex; align-items: center;}
    .cancel-detail a, .submit-price, .price-close p { font-size: 16px;}

    .van-icon-share-o { transform: scaleX(-1); font-size: 24px; margin-right: 5px; color: #999;}
    .submit-price { width: 184px; height: 52px; line-height: 52px; text-align: center; background: #6b18a8;}
  }
  @media (max-width: 750px) {
    .ShopChange {
      width: auto;margin: vw(40) vw(40);padding: vw(30); 
      .ShopChange-h1 { font-size: vw(36); font-weight: 400; margin-bottom: vw(36);}

      .mobList {min-height: vw(740);}
      .mobList-item-img {min-width: vw(166); width: vw(166); height: vw(166); margin-right: vw(22); overflow: hidden; border-radius: vw(10); background: #fefefe;}
      .mobList-item-img img, .mobList-item-d { width: 100%;}
      .mobList-item-d h1{ font-size: vw(28); margin-bottom: vw(15);}
      .mobList-item-d p {margin-bottom: vw(20);}
      .mobList-item-d p span {font-size: vw(36);}

      .addsub { width: vw(41); height: vw(49); line-height: vw(49);}
      .addsubInput { width: vw(88); height: vw(49); border-radius: 0; border: 0; border: 1px #ccc solid; padding: 0; box-sizing: border-box;}
      
      .price-close {padding: vw(30) ;}
      .price-close {height: vw(130); background: #f9f8f8;}
      .cancel-detail a, .submit-price, .price-close p { font-size: vw(24);}
      .price-close b {font-size: vw(40)!important;}
      .cancel-detail { padding-right: vw(10);}
      .submit-price { width: vw(300); height: vw(85);font-size: vw(28);line-height: vw(85);}
    }
  }
</style>
<style lang="scss">
.ShopChange {
  .el-table th,.el-table tr,.el-table--enable-row-hover .el-table__body tr:hover > td {background: #f5f0f8}
  .cell {display: flex;align-items: center;justify-content: center;}
}
</style>